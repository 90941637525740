// Icelandic
export default{
    form: {
        startSurveyButton: "Byrjum",
        nextButton: "Næsta",
        submitAnswerButton: "Senda svar",
        continueButton: "Halda áfram",
        finishButton: "Ljúka",
        headerPercentageCompletion: 'lokið',
        headerQuestionsCompleted: 'spurningar',
        headerDefaultErrorMessage: 'Úbbs! Eitthvað fór úrskeiðis.',
        brandingPoweredBy: 'Knúið af',
        brandingPromoWhySurvey: 'Af hverju að gera könnun þegar þú getur notað <b>{product}</b>?',
        brandingPromoGetStarted: 'Byrjaðu í dag!',
        submissionPending: "Voiceform er að senda. Ekki loka vafraglugganum.",
        submissionCanCloseWindow: "Voiceform þitt hefur verið sent. Þú getur lokað glugganum núna 👍",
        mediaAttachmentOverlayCloseButton: "Loka",
        expiredFormHeader: "Takk fyrir að heimsækja!",
        expiredFormMessage: "Það virðist sem þessi könnun sé útrunnin. Vinsamlega hafðu samband við höfund voiceform til að láta hann vita.",
        notPublishedFormHeader: "Voiceform er ekki birt!",
        notPublishedFormMessage: "Það virðist sem þessi könnun sé ekki birt. Hafðu samband við höfund voiceform til að láta vita.",
        notPublishedFormRetryButton: "Reyna aftur",
        errorFormHeader: "Úbbs!",
        errorFormMessage: "Eitthvað fór úrskeiðis. Vinsamlegast reyndu aftur síðar.",
        errorFormRetryButton: "Reyna aftur",
        emptyFormHeader: "Takk fyrir að koma við!",
        emptyFormMessage: "Úbbs! Það virðist sem þetta eyðublað sé autt, án spurninga eða reita til að fylla út. Við biðjumst velvirðingar á óþægindunum. Vinsamlega hafðu samband við höfund eyðublaðsins til að láta vita um vandamálið. Takk fyrir skilninginn og samvinnuna!",

        restoreFormHeader: "Það lítur út fyrir að þú hafir ólokið eyðublað!",
        restoreFormMessage: "Viltu halda áfram þar sem frá var horfið?",
        restoreFormButton: "Halda áfram með eyðublaðið",
        restoreFormButtonNew: "Byrja upp á nýtt",

        audioPermissionHeader: "Viltu svara með rödd?",
        audioPermissionMessage: "Tala gerir svörun hraða og einfalda.",
        audioPermissionAcceptButton: "Já, ég vil",
        audioPermissionDenyButton: "Nei, takk",
        audioPermissionErrorHeader: "Hljóðnemi er ekki tiltækur",
        audioPermissionErrorMessage: "Vinsamlegast athugaðu heimildir vafrans til að leyfa aðgang að hljóðnema.",
        audioPermissionErrorButton: "Í lagi",
        audioRecorderFailedUpload: "Ekki tókst að hlaða upp upptöku",
        silenceDetectorError: "Við heyrum þig ekki. Athugaðu stillingar hljóðnemans. Ábending: Þetta gæti verið ytri hljóðnemi.",
        audioRecorderButtonRecord: "Byrja upptöku",
        audioRecorderButtonRecordMore: "Taka upp meira",
        voiceResponseSelectionMessage: "Veldu hvernig þú vilt svara...",
        voiceResponseSelectionOrDivider: "eða",
        voiceResponseTextInputPlaceholder: "Sláðu inn svar",
        voiceResponseTextInputCharacterCounterMinMessage: "Vinsamlegast sláðu inn að minnsta kosti {min} stafi",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} stafir (lágmark {min} stafir)",
        voiceResponseTextInputCharacterValidationMessage: "{count} stafir (lágmark {min} stafir)",

        // checkbox
        checkboxValidationTooFew: "Þú verður að velja að minnsta kosti {min} valkost(i)",
        checkboxValidationTooMany: "Þú mátt ekki velja fleiri en {max} valkost(i)",
        checkboxNoneOfTheAboveOption: "Ekkert af ofangreindu",
        checkboxInvalidAnswer: "Ógilt svar.",

        // datepicker
        datePickerPlaceholder: "Veldu dagsetningu",

        // dropdown
        // email
        emailLabel: "Netfang",
        emailInvalid: "Ógilt netfang.",
        // file-upload
        fileUploadPluginNameCamera: "Myndavél",
        fileUploadPluginNameCameraVideo: "Taka upp myndband",
        fileUploadPluginScreenCast: "Skjástreymi",
        fileUploadVideoImportFilesDefault: 'Taktu upp eða hlaða upp myndbandi í gegnum:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Veldu valkost til að taka upp myndband:',
        fileUploadVideoImportFilesNoCamera: 'Ýttu á hnapp til að taka upp skjámyndband',
        fileUploadVideoImportFilesNoScreenCast: 'Ýttu á hnapp til að taka upp myndband',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Engir valkostir tiltækir til að taka upp myndband',

        // matrix
        matrixValidationMessage: "Svarið er gilt.",
        matrixRow: "Röð",
        matrixColumn: "Dálkur",
        matrixRowRequired: "Röðin {rowTitle} er nauðsynleg.",
        matrixRadioGroup: "Radíóhópur",
        matrixCheckboxGroup: "Merkiboxahópur",
        matrixGroupRequired: "Fyrir röð {rowTitle}. {type} {groupTitle} er nauðsynlegt.",
        matrixColumnRequired: "Fyrir röð {rowTitle}. Dálkurinn {columnTitle} er nauðsynlegur.",
        matrixColumnInvalid: "Fyrir röð {rowTitle}. Dálkurinn {columnTitle} er ógildur.",
        matrixRequired: "Nauðsynlegt.",
        matrixNumericMustBeNumber: "Verður að vera tala.",
        matrixNumericMustBeGreaterThenMin: "Verður að vera stærri en {min}.",
        matrixNumericMustBeLessThenMax: "Verður að vera minni en {max}.",
        matrixNumericMustBeInteger: "Verður að vera heiltala.",
        matrixNumericInvalidNumber: "Ógild tala.",

        // name
        nameLabel: "Nafn",
        nameInvalid: "Sláðu inn fornafn og eftirnafn.",

        // nps
        npsNotLikely: "Lítil líkur",
        npsExtremelyLikely: "Mjög líklegt",

        // numeric input
        numericInputRequired: "Nauðsynlegt.",
        numericInputMustBeNumber: "Verður að vera tala.",
        numericInputMustBeGreaterThenMin: "Verður að vera stærri en {min}.",
        numericInputMustBeLessThenMax: "Verður að vera minni en {max}.",
        numericInputMustBeInteger: "Verður að vera heiltala.",
        numericInputInvalidNumber: "Ógild tala.",
        numericInputPlaceholder: "Sláðu inn tölu",

        // phone
        phoneInvalid: "Símanúmerið er ógilt.",
        phoneCountrySelectorLabel: 'Landsnúmer',
        phoneCountrySelectorError: 'Veldu land',
        phoneNumberLabel: 'Símanúmer',
        phoneExample: 'Dæmi:',

        // boolean
        booleanYesLabel: "Já",
        booleanNoLabel: "Nei",

        //questionStep
        questionStepAudioQuestionLabel: "Hljóðspurning",

        // errors
        invalidPhoneNumber: "{phone} er ógilt símanúmer.",
        invalidEmail: "{email} er ógilt netfang.",
        questionIsRequired: "Spurningin er nauðsynleg.",
        answerIsNotValid: "Svarið er ekki gilt.",
        unfinishedProbingDialogError: "Vinsamlega ljúktu við samtalið.",
        cannotResumePartialResponse: "Ekki hægt að halda áfram með hlutsvörun.",
        failedToSubmitForm: "Ekki tókst að senda formið. Athugaðu nettenginguna og reyndu aftur.",

        //language picker
        searchLanguage: "Leita að tungumáli",
    }
}
